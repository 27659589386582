<template>
    <div>
        <div class="loading" v-if="fetchData">
            <load-data></load-data>
        </div>
        <div class="attainmentCO" v-else>
            <b-row>
                <b-col md="12">
                    <div>
                        <div>
                            <table class="table table-bordered table-striped">
                                <thead class="thead-dark text-left">
                                    <tr>
                                        <th></th>
                                        <th class="align-middle" v-for="(co, coIndex) in reports.course_outcomes"
                                            :key="coIndex">
                                            {{ co.code }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="reports.enable_assignment">
                                        <td class="align-middle">CO Attainment of Assignment</td>
                                        <td v-for="(co, coIndex) in reports.course_outcomes" :key="coIndex">
                                            <span v-if="reports.assignmentAttainment[co.id].attainment_level">
                                                {{ reports.assignmentAttainment[co.id].attainment_level }}
                                            </span>
                                            <span v-else>
                                                {{ reports.assignmentAttainment[co.id].max_stud > 0 ? '' : '-' }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr v-if="reports.enable_assessment">
                                        <td class="align-middle">CO Attainment of Assessment</td>
                                        <td v-for="(co, coIndex) in reports.course_outcomes" :key="coIndex">
                                            <span v-if="reports.assessmentAttainment[co.id].attainment_level">
                                                {{ reports.assessmentAttainment[co.id].attainment_level }}
                                            </span>
                                            <span v-else>
                                                {{ reports.assessmentAttainment[co.id].max_stud > 0 ? '' : '-' }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr v-if="(courseDetails.type && courseDetails.type.slug == 'laboratory')">
                                        <td class="align-middle">CO Attainment of Laboratory Evaluation</td>
                                        <td class="align-middle" v-for="(co, coIndex) in reports.course_outcomes"
                                            :key="coIndex">
                                            {{
                                            reports.laboratoryAttainment[co.id] ==
                                            null
                                            ? ''
                                            : handleValue(
                                            reports.laboratoryAttainment[co.id]
                                            )
                                            }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <h4>Calculating Attainment of CIE(Continouse Internal Evaluation) by
                                applying
                                {{ Number(reports.attainmentToolsWeightage.assignment).toFixed(2) }} %
                                weightage to Assignment and
                                {{Number(reports.attainmentToolsWeightage.assessment).toFixed(2) }} %
                                weightage to Assessment</h4>

                            <table class="table table-bordered table-striped">
                                <thead class="thead-dark text-left">
                                    <tr>
                                        <th></th>
                                        <th class="align-middle" v-for="(co, coIndex) in reports.course_outcomes"
                                            :key="coIndex">
                                            {{ co.code }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="reports.enable_assignment">
                                        <td class="align-middle">CO Attainment of CIE</td>
                                        <td v-for="(co, coIndex) in reports.course_outcomes" :key="coIndex">
                                            <span v-if="reports.coAttainmentContinuousInternalEvaluation[co.id]">
                                                {{ reports.coAttainmentContinuousInternalEvaluation[co.id].toFixed(2) }}
                                            </span>
                                            <span v-else>
                                                {{ (reports.assignmentAttainment[co.id].max_stud +
                                                reports.assessmentAttainment[co.id].max_stud) > 0 ? ' ' : '-' }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr v-if="reports.enable_assessment">
                                        <td class="align-middle">CO Attainment of SEE (University Exam)</td>
                                        <td class="align-middle" v-for="(co, coIndex) in reports.course_outcomes"
                                            :key="coIndex">
                                            <span
                                                v-if="reports.boardexamAttainment['computed_co_attainment'][co.id].attainment_level">
                                                {{
                                                reports.boardexamAttainment['computed_co_attainment'][co.id].attainment_level
                                                }}
                                            </span>
                                            <span v-else>
                                                <span v-if="reports.boardexamAttainment['equation_type'] == 1">
                                                    {{
                                                    reports.boardexamAttainment['computed_co_attainment'][co.id].max_stud
                                                    > 0 ? '' : '-' }}
                                                </span>
                                                <span v-else>
                                                    {{
                                                    reports.boardexamAttainment['computed_co_attainment'][co.id].max_student_count
                                                    > 0 ? '' : '-' }}
                                                </span>

                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <h4>Calculating Direct Attainment by applying
                                {{ Number(reports.attainmentToolsWeightage.cie) }} weightage to CIE and
                                {{ reports.attainmentToolsWeightage.boardexam }} weightage to SEE</h4>
                            <table class="table table-bordered table-striped">
                                <thead class="thead-dark text-left">
                                    <tr>
                                        <th></th>
                                        <th class="align-middle" v-for="(co, coIndex) in reports.course_outcomes"
                                            :key="coIndex">
                                            {{ co.code }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="align-middle">Direct Attainment</td>
                                        <td v-for="(co, coIndex) in reports.course_outcomes" :key="coIndex">
                                           <span v-if="reports.direct_attainment[co.id]===null">
                                                -
                                           </span>
                                           <span v-else>
                                            <span v-if="reports.direct_attainment[co.id]>0">
                                                {{ reports.direct_attainment[co.id].toFixed(2) }}
                                            </span>
                                           </span> 
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="align-middle">Indirect Attainment</td>
                                        <td v-for="(co, coIndex) in reports.course_outcomes" :key="coIndex">
                                            <span v-if="reports.surveyAttainment[co.id]">
                                                {{ reports.surveyAttainment[co.id] }}
                                            </span>
                                            <span v-else>
                                                {{ reports.surveyAttainment[co.id] == 0 ? ' ' : '-' }}
                                            </span>
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                            <h4> Calculating Final CO Attainment of the course by applying {{
                                reports.directmethod_weigtage }} weightage to CO Direct Attainment and {{
                                reports.indirectmethod_weigtage }} weightage to CO Indirect Attainment</h4>

                            <table class="table table-bordered table-striped">
                                <thead class="thead-dark text-left">
                                    <tr>
                                        <th></th>
                                        <th class="align-middle" v-for="(co, coIndex) in reports.course_outcomes"
                                            :key="coIndex">
                                            {{ co.code }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="align-middle">Final CO Attainment of Course</td>
                                        <td v-for="(co, coIndex) in reports.course_outcomes" :key="coIndex">
                                            <span v-if="reports.final_attainment[co.id] == null">
                                                -
                                            </span>
                                            <span v-if="reports.final_attainment[co.id] > 0">
                                                {{ reports.final_attainment[co.id] }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="align-middle">
                                            CO Attainment Average
                                        </td>
                                        <span> {{ reports.finalattainmentAvg.toFixed(2) }}</span>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </b-col>
            </b-row>


            <div class="table-responsive">
                <table class="table table-bordered table-striped">
                    <thead class="thead-dark text-left">
                        <tr>
                            <th colspan="2">Download Reports PDF </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Course Outcomes</td>
                            <td>
                                <div>
                                    <b-button @click="downloadAttainmentReport('courseoutcome', false)" variant="info"
                                        size="sm" :disabled="btn.disabled">Download Report</b-button>
                                    <b-button style="margin-left: 1px" variant="primary"
                                        @click="downloadAttainmentReport('courseoutcome', true)" size="sm">View Report
                                    </b-button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>CO Attainment Of Assignment</td>
                            <td>
                                <div>
                                    <b-button @click="downloadAttainmentReport('assignment', false)" variant="info"
                                        size="sm" :disabled="btn.disabled">
                                        Download Report</b-button>
                                    <b-button style="margin-left: 1px" variant="primary"
                                        @click="downloadAttainmentReport('assignment', true)" size="sm">View Report
                                    </b-button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>CO Attainment Of Assessment</td>
                            <td>
                                <div>
                                    <b-button @click="downloadAttainmentReport('assessment', false)" variant="info"
                                        size="sm" :disabled="btn.disabled">
                                        Download Report</b-button>
                                    <b-button style="margin-left: 1px" variant="primary"
                                        @click="downloadAttainmentReport('assessment', true)" size="sm">View Report
                                    </b-button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>CO Attainment Of Laboratory Evaluation</td>
                            <td>
                                <div>
                                    <b-button @click="downloadAttainmentReport('laboratory', false)" variant="info"
                                        size="sm" :disabled="btn.disabled">
                                        Download Report</b-button>
                                    <b-button style="margin-left: 1px" variant="primary"
                                        @click="downloadAttainmentReport('laboratory', true)" size="sm">View Report
                                    </b-button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>CO Attainment Of University Exam</td>
                            <td>
                                <div>
                                    <b-button @click="downloadAttainmentReport('boardexam', false)" variant="info"
                                        size="sm" :disabled="btn.disabled">
                                        Download Report</b-button>
                                    <b-button style="margin-left: 1px" variant="primary"
                                        @click="downloadAttainmentReport('boardexam', true)" size="sm">View Report
                                    </b-button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>CO Attainment Of Course Exit Survey</td>
                            <td>
                                <div>
                                    <b-button @click="downloadAttainmentReport('courseexitsurvey', false)"
                                        variant="info" size="sm" :disabled="btn.disabled">Download Report</b-button>
                                    <b-button style="margin-left: 1px" variant="primary"
                                        @click="downloadAttainmentReport('courseexitsurvey', true)" size="sm">View
                                        Report
                                    </b-button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Final CO Attainment of Course</td>
                            <td>
                                <div>
                                    <b-button @click="downloadAttainmentReport('attainment', false)" variant="info"
                                        size="sm" :disabled="btn.disabled">
                                        Download Report</b-button>
                                    <b-button style="margin-left: 1px" variant="primary"
                                        @click="downloadAttainmentReport('attainment', true)" size="sm">View Report
                                    </b-button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Final PO Attainment of Course</td>
                            <td>
                                <div>
                                    <b-button @click="
                                        downloadAttainmentReport('co_po_pso_mapping', false)
                                        " variant="info" size="sm" :disabled="btn.disabled">
                                        Download Report</b-button>
                                    <b-button style="margin-left: 1px" variant="primary"
                                        @click="downloadAttainmentReport('co_po_pso_mapping', true)" size="sm">View
                                        Report
                                    </b-button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["courseReport", "courseDetails"],
    async created() {
        this.fetchData = true;
        await this.getAttainmentReport();
        this.fetchData = false;
    },
    data() {
        return {
            fetchData: false,
            assignmentwt: null,
            assessmentwt: null,
            assignments: [],
            assignmentQuestions: {},
            selectedAssignments: [],
            targetPercentage: "",
            assignmentsArray: [],
            reports: {},
            btn: {
                text: "DOWNLOAD PDF REPORT",
                disabled: false,
            },
            reportType: 0,
            sum: 0,
        }
    },
    methods: {
        async downloadAttainmentReport(step, viewReport) {
            // this.downloadData = true;
            // this.$bvModal.hide("attainmentReport");
            this.btn = {
                text: "Please wait...",
                disabled: true,
            };
            const url =
                this.$store.getters.getAPIKey.mainAPI +
                `/coattainment/report/pdfexport`;
            await this.$axios
                .post(
                    url, {
                    course: this.courseReport.course_id,
                    step: step
                }, {
                    responseType: "blob"
                }
                )
                .then((response) => {
                    const pdfBlob = new Blob([response.data], {
                        type: "application/pdf",
                    });
                    const pdfUrl = window.URL.createObjectURL(pdfBlob);
                    let newTab = null;
                    if (viewReport) {
                        newTab = window.open(pdfUrl, "_blank");
                    }
                    if (!newTab) {
                        if (viewReport)
                            this.$toast.error(
                                "Failed to show pdf file, insted the file will be downloaded", {
                                position: "top",
                                duration: 3000,
                            }
                            );
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        if(step == 'courseoutcome'){
                            link.setAttribute("download",`Course Outcomes-${this.courseReport.course_code}-${this.courseReport.usersubgroup_code}-${this.courseReport.usergroup_code}.pdf` )
                        }
                        else if(step == 'assignment'){
                            link.setAttribute("download",`CO Attainment Of Assessment-${this.courseReport.course_code}-${this.courseReport.usersubgroup_code}-${this.courseReport.usergroup_code}.pdf` )
                        }
                        else if(step == 'assessment'){
                            link.setAttribute("download",`CO Attainment Of Assignment-${this.courseReport.course_code}-${this.courseReport.usersubgroup_code}-${this.courseReport.usergroup_code}.pdf` )
                        }
                        else if(step == 'laboratory'){
                            link.setAttribute("download",`CO Attainment Of Laboratory Evaluation-${this.courseReport.course_code}-${this.courseReport.usersubgroup_code}-${this.courseReport.usergroup_code}.pdf` )
                        }
                        else if(step == 'boardexam'){
                            link.setAttribute("download",`CO Attainment Of University Exam-${this.courseReport.course_code}-${this.courseReport.usersubgroup_code}-${this.courseReport.usergroup_code}.pdf` )
                        }
                        else if(step == 'courseexitsurvey'){
                            link.setAttribute("download",`CO Attainment Of Course Exit Survey-${this.courseReport.course_code}-${this.courseReport.usersubgroup_code}-${this.courseReport.usergroup_code}.pdf` )
                        }
                        else if(step == 'attainment'){
                            link.setAttribute("download",`Final CO Attainment Of Course-${this.courseReport.course_code}-${this.courseReport.usersubgroup_code}-${this.courseReport.usergroup_code}.pdf` )
                        }
                        else{
                            link.setAttribute("download",`Final PO Attainment Of Course-${this.courseReport.course_code}-${this.courseReport.usersubgroup_code}-${this.courseReport.usergroup_code}.pdf` )
                        }  
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        this.$toast.error(error.response.data.message, {
                            position: "top",
                            duration: 3000,
                        });
                    } else {
                        this.$toast.error("Failed to download", {
                            position: "top",
                            duration: 3000,
                        });
                    }
                    // this.downloadData = false;
                });
            this.btn = {
                text: "DOWNLOAD PDF REPORT",
                disabled: false,
            };
        },

        async saveAttainmentConfigs() {
            const url =
                this.$store.getters.getAPIKey.mainAPI +
                `/coattainment/savedata`;
            await this.$axios
                .post(url, {
                    course: this.courseReport.course_id
                })
                .then(() => {
                    this.$toast.success('Configuration saved successfully', {
                        position: "top",
                        duration: 3000,
                    });
                })
                .catch(() => {
                    this.$toast.error('Failed save configuration', {
                        position: "top",
                        duration: 5000,
                    });
                });
        },
        handleValue(num) {
            if (isNaN(num)) {
                return "";
            } else {
                return num % 1 == 0 ? Math.floor(num) : parseFloat(num).toFixed(2);
            }
        },
        async getAttainmentConfigration() {

        },
        async setAttainmentConfigration() {

        },
        async getAttainmentReport() {
            const url =
                this.$store.getters.getAPIKey.mainAPI + `/coattainment/report`;
            await this.$axios
                .post(url, {
                    course: this.courseReport.course_id,
                    step: "attainment",
                })
                .then((response) => {
                    this.reports = response.data;
                });
        },
    },
};
</script>
