<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="attainmentCO" v-else>
      <b-row>
        <b-col md="12">
          <div class="viewOrConfig mb-3">
            <b-button size="sm" :variant="config && !report ? 'success' : 'outline-primary'" class="font-weight-bolder"
              disabled>Choose Assignment</b-button>
            <b-button size="sm" disabled :variant="!config && !report ? 'success' : 'outline-primary'"
              class="ml-4 font-weight-bolder">View Data</b-button>
            <b-button size="sm" disabled :variant="report ? 'success' : 'outline-primary'"
              class="ml-4 font-weight-bolder">View Report</b-button>
          </div>

          <div v-if="config && !report">
            <!-- <div class="targetPercentage mb-2">
              <span class="font-weight-bolder">Target Percentage:</span>
              <div class="d-flex align-items-center">
                <b-form-input v-model="targetPercentage" style="width: 100px; text-align: center"></b-form-input>
                %
              </div>
            </div> -->
            <div class="table-responsive">
              <table class="table table-bordered table-striped">
                <thead class="thead-dark text-left">
                  <tr>
                    <th>
                      <b-form-checkbox @change="selectAllData" v-model="selectAll">{{
                        selectAll ? "Unselect All" : "Select All"
                        }}</b-form-checkbox>
                    </th>
                    <th>Assignment</th>
                    <th>Questions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(assignment, assignmentIndex) in assignments" :key="assignmentIndex">
                    <td class="align-middle text-left">
                      <b-form-checkbox size="sm" @change="getAssignmentQuestions(assignment.value)"
                        v-model="assignmentQuestions[assignment.value].checked">
                      </b-form-checkbox>
                    </td>
                    <td class="align-middle text-left">
                      {{ assignment.text }}
                    </td>
                    <td class="align-middle text-left">
                      <div class="d-flex flex-wrap">
                        <p v-if="
                          assignmentQuestions[assignment.value]
                            .co_score_evaluation
                        ">
                          Evaluation by CO score
                        </p>
                        <div v-for="(ques, quesIndex) in assignmentQuestions[
                          assignment.value
                        ].questions" :key="quesIndex">
                          <span :id="`ques${assignment.value}` + quesIndex" :class="
                            ques.name != null ? 'text-info' : 'text-dark'
                          ">
                            <b-form-checkbox class="ml-3" v-model="
                              assignmentQuestions[assignment.value]
                                .selectedQues
                            " :value="ques.value">
                              {{ ques.text }}
                            </b-form-checkbox>
                          </span>
                          <b-popover v-if="ques.name != null" :target="`ques${assignment.value}` + quesIndex"
                            triggers="hover" placement="top">
                            {{ ques.name }}
                          </b-popover>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div v-if="!config && !report">
            <div class="table-responsive">
              <table class="table table-bordered table-striped">
                <thead class="thead-dark text-left">
                  <tr>
                    <th class="text-center" colspan="100">
                      Ques. No.,Max Mark,Course Outcomes - Activity Name
                    </th>
                  </tr>
                  <tr>
                    <th></th>
                    <th v-for="(question,
                    questionIndex) in assignmentsArray.questions" :key="questionIndex">
                      <div class="d-flex">
                        {{ question.question_no }}
                        ({{ question.maximum_mark }})
                        <div v-if="question.course_outcome_ids.length != 0">
                          <span v-for="(outcome,
                          outcomeIndex) in question.course_outcome_ids" :key="outcomeIndex">
                            {{ assignmentsArray.course_outcomes[outcome].code }}
                            <span v-if="
                              outcomeIndex !=
                              question.course_outcome_ids.length - 1
                            ">,</span>
                          </span>
                        </div>
                      </div>
                      -
                      {{
                      assignmentsArray.activities[question.activity_id].name
                      }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(student,
                  studentIndex) in assignmentsArray.participants" :key="studentIndex">
                    <td class="align-middle">
                      {{ student.first_name || " " }}
                      {{ student.last_name || " " }}
                    </td>
                    <td class="align-middle" v-for="(question,
                    questionIndex) in assignmentsArray.questions" :key="questionIndex">
                      <span
                        v-if="assignmentsArray.participantsSelectedScoreQuestionIds[student.id].includes(question.id)">
                        {{
                        handleValue(
                        assignmentsArray.paricipantQuestionScores[student.id][
                        question.id
                        ]
                        )
                        }}</span>
                      <span style="font-size: 10px !important;" v-else>
                        {{
                        handleValue(
                        assignmentsArray.paricipantQuestionScores[student.id][
                        question.id
                        ]
                        )
                        }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="table-responsive" v-if="report">
            <table class="table table-striped table-bordered" v-if="reports.attainment_data.equation_type==1">
              <thead class="thead-dark">
                <tr>
                  <th class="text-center" colspan="100">Student Vs Questions</th>
                </tr>
                <tr>
                  <th class="text-center" colspan="100">
                    Ques. No.) Course Outcome - Activity Name
                  </th>
                </tr>
                <tr>
                  <th></th>
                  <th v-for="(question,
                    questionIndex) in assignmentsArray.questions" :key="questionIndex">
                    <div class="d-flex">
                      {{ question.question_no }})
                      <div v-if="question.course_outcome_ids.length != 0">
                        <span v-for="(outcome,
                          outcomeIndex) in question.course_outcome_ids" :key="outcomeIndex">

                          <span> {{ assignmentsArray.course_outcomes[outcome].code }}
                            <span v-if="
                              outcomeIndex !=
                              question.course_outcome_ids.length - 1
                            ">,</span>
                          </span>
                        </span>
                      </div>
                    </div>
                    -
                    {{
                    assignmentsArray.activities[question.activity_id].name
                    }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(student, studentIndex) in reports.participants" :key="studentIndex">
                  <td class="align-middle">
                    {{ student.first_name || " " }}
                    {{ student.last_name || " " }}
                  </td>
                  <td class="align-middle" v-for="(question,
                    questionIndex) in assignmentsArray.questions" :key="questionIndex">
                    {{
                    handleValue(
                    reports.attainment_data.participantQuestionScorePersantage[
                    student.id
                    ][question.id]
                    )
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="align-middle">Target Acheived Student Count</td>
                  <td class="align-middle" v-for="(question,
                    questionIndex) in assignmentsArray.questions" :key="questionIndex">
                    {{
                    handleValue(
                    reports.attainment_data.computed_question_datas[question.id]
                    .targetAchived
                    )
                    }}
                  </td>
                </tr>
                <tr class="align-middle">
                  <td class="align-middle">Max Students Count</td>
                  <td class="align-middle" v-for="(question,
                    questionIndex) in assignmentsArray.questions" :key="questionIndex">
                    {{
                    handleValue(
                    reports.attainment_data.computed_question_datas[question.id]
                    .max_stud
                    )
                    }}
                  </td>
                </tr>
                <tr class="align-middle">
                  <td class="align-middle">Target Attainment Percentage %</td>
                  <td class="align-middle" v-for="(question,
                    questionIndex) in assignmentsArray.questions" :key="questionIndex">
                    {{
                    handleValue(
                    reports.attainment_data.computed_question_datas[question.id]
                    .target_achived_persantage
                    )
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="align-middle">Attainment Level</td>
                  <td class="align-middle" v-for="(question,
                    questionIndex) in assignmentsArray.questions" :key="questionIndex">
                     <span v-if="reports.attainment_data.computed_question_datas[question.id].attainment_level">
                      {{ reports.attainment_data.computed_question_datas[question.id].attainment_level }}
                    </span>
                    <span v-else>
                      {{ reports.attainment_data.computed_question_datas[question.id].max_stud > 0 ? '' : '-' }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>

            <table class="table table-striped table-bordered" v-if="reports.attainment_data.equation_type==2">
              <thead class="thead-dark">
                <tr>
                  <th class="text-center" colspan="100">Student Vs Outcomes</th>
                </tr>
                <tr>
                  <th></th>
                  <th v-for="(co, coIndex) in reports.course_outcomes" :key="coIndex">
                    {{ co.code }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(student, studentIndex) in reports.participants" :key="studentIndex">
                  <td class="align-middle">
                    {{ student.first_name || " " }}
                    {{ student.last_name || " " }}
                  </td>
                  <td class="align-middle" v-for="(co, coIndex) in reports.course_outcomes" :key="coIndex">
                    <span v-if="reports.attainment_data.participantCoScorePersantage[student.id][co.id]">
                      {{ (reports.attainment_data.participantCoScorePersantage[student.id][co.id]).toFixed(2) }}
                    </span>
                    <span v-else>
                      {{ reports.attainment_data.computed_co_datas[co.id].max_stud > 0 ? 0 : '-' }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="align-middle">Target Acheived Student Count</td>
                  <td class="align-middle" v-for="(co, coIndex) in reports.course_outcomes" :key="coIndex">
                    {{ reports.attainment_data.computed_co_datas[co.id].targetAchived ?
                    reports.attainment_data.computed_co_datas[co.id].targetAchived
                    : reports.attainment_data.computed_co_datas[co.id].max_stud > 0 ? ' ' : '-'
                    }}
                  </td>
                </tr>
                <tr class="align-middle">
                  <td class="align-middle">Max Students Count</td>
                  <td class="align-middle" v-for="(co, coIndex) in reports.course_outcomes" :key="coIndex">
                    {{ reports.attainment_data.computed_co_datas[co.id].max_stud ?
                    reports.attainment_data.computed_co_datas[co.id].max_stud
                    : !reports.attainment_data.computed_co_datas[co.id].max_stud ? '-' : ''
                    }}
                  </td>
                </tr>
                <tr class="align-middle">
                  <td class="align-middle">Target Attainment Percentage %</td>
                  <td class="align-middle" v-for="(co, coIndex) in reports.course_outcomes" :key="coIndex">
                    <span v-if="reports.attainment_data.computed_co_datas[co.id].target_achived_persantage">
                      {{ (reports.attainment_data.computed_co_datas[co.id].target_achived_persantage).toFixed(2) }}
                    </span>
                    <span v-else>
                      {{ reports.attainment_data.computed_co_datas[co.id].max_stud > 0 ? '' : '-' }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="align-middle">Attainment Level</td>
                  <td class="align-middle" v-for="(co, coIndex) in reports.course_outcomes" :key="coIndex">
                    <span v-if="reports.attainment_data.computed_co_datas[co.id].attainment_level">
                      {{ reports.attainment_data.computed_co_datas[co.id].attainment_level }}
                    </span>
                    <span v-else>
                      {{ reports.attainment_data.computed_co_datas[co.id].max_stud > 0 ? '' : '-' }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  props: ["courseReport"],
  async created() {
    this.fetchData = true;
    await this.getAttainmentConfigration();
    this.fetchData = false;
  },
  data() {
    return {
      skip: false,
      fetchData: false,
      assignments: [],
      assignmentQuestions: {},
      selectedAssignments: [],
      // targetPercentage: "",
      assignmentsArray: [],
      config: true,
      reports: {},
      selectAll: true,
      report: false,
    };
  },
  methods: {
    selectAllData(status) {
      this.selectedAssignments = [];
      if (status) {
        for (let assignment in this.assignmentQuestions) {
          this.assignmentQuestions[assignment].checked = true;
          this.assignmentQuestions[assignment].questions.forEach((ques) => {
            this.assignmentQuestions[assignment].selectedQues.push(ques.value);
          });
        }
      } else {
        for (let assignment in this.assignmentQuestions) {
          this.assignmentQuestions[assignment].checked = false;
          this.assignmentQuestions[assignment].selectedQues = [];
        }
      }
    },
    handleValue(num) {
      if (isNaN(num)) {
        return "";
      } else {
        return num % 1 == 0 ? Math.floor(num) : parseFloat(num).toFixed(2);
      }
    },
    getAssignmentQuestions(value) {
      this.assignmentQuestions[value];
      if (this.assignmentQuestions[value].questions.length == 0) {
        return this.$toast.warning(`No questions found in this assignment!`, {
          position: "top",
          duration: 3000,
        });
      }
      if (this.assignmentQuestions[value].selectedQues.length != 0) {
        this.assignmentQuestions[value].selectedQues = [];
      } else {
        this.assignmentQuestions[value].questions.forEach((ques) => {
          this.assignmentQuestions[value].selectedQues.push(ques.value);
        });
      }
    },

    async getAttainmentConfigration() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/coattainment/getconfig`;
      await this.$axios
        .post(url, { course: this.courseReport.course_id, step: "assignment" })
        .then((response) => {
          // let assignments = response.data;
          let questions = response.data.exams_with_questions;
          let selectedQuestions = response.data.selected_questions;
          let selectedExams = response.data.selected_exams;
          // this.targetPercentage = response.data.target_persantage;
          questions.forEach((assignment) => {
            this.assignments.push({
              value: assignment.id,
              text: assignment.name,
            });

            this.$set(this.assignmentQuestions, assignment.id, {
              questions: [],
              selectedQues: [],
              checked: selectedExams.includes(assignment.id),
              co_score_evaluation: assignment.co_score_evaluation,
            });

            assignment.questions.forEach((ques) => {
              this.assignmentQuestions[assignment.id].questions.push({
                value: ques.id,
                text: ques.question_no,
                name: ques.name == "" ? null : ques.name,
              });
              if (selectedQuestions.includes(ques.id))
                this.assignmentQuestions[assignment.id].selectedQues.push(
                  ques.id
                );
            });
          });
        });
    },
    async setAttainmentConfigration() {
      let co_score_evaluation_activities = [];
      for (let assignment in this.assignmentQuestions) {
        if (
          this.assignmentQuestions[assignment].checked &&
          this.assignmentQuestions[assignment].co_score_evaluation
        )
          co_score_evaluation_activities.push(assignment);

        await this.assignmentQuestions[assignment].selectedQues.forEach(
          (ques) => {
            this.selectedAssignments.push(ques);
          }
        );
      }

      // if(this.selectedAssignments.length==0) this.skip=true;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/coattainment/setconfig`;
      await this.$axios
        .post(url, {
          course: this.courseReport.course_id,
          step: "assignment",
          activity_questions: this.selectedAssignments,
          co_score_evaluation_activities: co_score_evaluation_activities,
          // target_persantage: this.targetPercentage,
        })
        .then(() => { });
    },
    async getAttainmentData() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/coattainment/getdata`;
      await this.$axios
        .post(url, {
          course: this.courseReport.course_id,
          step: "assignment",
        })
        .then((response) => {
          this.assignmentsArray = response.data;
        });
    },
    async getAttainmentReport() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/coattainment/report`;
      await this.$axios
        .post(url, {
          course: this.courseReport.course_id,
          step: "assignment",
        })
        .then((response) => {
          this.reports = response.data;
        });
    },
    async getAttainmentCourseOutcomes() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/coattainment/getdata`;
      await this.$axios
        .post(url, {
          course: this.courseReport.course_id,
          step: "assignment",
        })
        .then(() => { });
    },
  },
};
</script>
